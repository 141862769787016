.App {
    text-align: center;
    /*height: 97.5vh;*/
    height: 95vh;
  }
  
.leaflet-container {
width: 100%;
height: 100%;
border-radius:10px;
}
.leaflet-control-attribution{
  display:none;
}
.leaflet-popup-content-wrapper{
  /* 矩形 2 */
  background:none;

  backdrop-filter: blur(5px);
}
.leaflet-popup-tip{
  background:none;

  backdrop-filter: blur(5px);
}
.leaflet-bar a{
  background:none;

  backdrop-filter: blur(5px);
}
.leaflet-touch .leaflet-bar a {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.safety-score {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px gray;
  font-size: 16px;
}